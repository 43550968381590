<template>
  <div class="buttonWrapper">
    <!-- 列表操作按钮 -->
    <div style="width: 100%; height: 30px">
      <span>
        <ul class="top-btns pull-right" v-if="type === 'kanban' ? false : true">
          <li
            v-for="(item, idx) in btnList.filter((item,index)=> {return showBtn(index)})"
            :key="idx"
            class="btn pull-left"
          >
            <span
              class="btnName"
              v-if="item.action !== 'CustomButton'"
              @click="handleClick(item, item.action)"
              >{{ item.label }}</span
            >
            <!-- 自定义按钮 -->
            <span
              class="btnName"
              v-if="
                item.action === 'CustomButton' &&
                (item.event === 'JavaScript' || item.event === 'URL')
              "
              :click="item.functionCode"
              @click="customButton(item)"
              >{{ item.label }}</span
            >
            <span
              class="btnName"
              v-if="
                item.action === 'CustomButton' &&
                (item.event === 'lightning' || item.event === 'lightning-url')
              "
              :click="item.functionCode"
              @click="handleClick(item, item.action)"
              >{{ item.label }}</span
            >
            <el-popover
              placement="bottom"
              trigger="click"
              popper-class="pop-button"
              v-if="btnList.length > 4"
            >
              <div
                style="height: 27px; border-left: 1px solid #dedcda"
                class="el-icon-caret-bottom icon"
                slot="reference"
                v-show="idx === 3"
                @click="showMoreBtn = !showMoreBtn"
              ></div>
              <!-- 更多按钮 -->
              <ul>
                <li
                  v-for="(item, idx) in btnList.filter((item,index)=>
                         {return showMoreBtns(index) && item.action !== 'CustomButton'})"
                  :key="idx"
                  class="textItem"
                  @click="handleClick(item, item.action, true)"
                >
                  {{ item.label }}
                </li>
                <!-- 自定义按钮,JavaScript类型 -->
                <li
                  v-for="(item, idx) in btnList.filter((item,index)=>
                         {return showMoreBtns(index) &&
                          item.action === 'CustomButton' && item.event === 'JavaScript'})"
                  :key="idx"
                  class="textItem"
                  @click="customButton(item)"
                >
                  {{ item.label }}
                  <button
                    v-show="false"
                    :id="item.name"
                    :onclick="item.functionCode"
                  ></button>
                </li>
                <li
                  v-for="(item, idx) in btnList.filter((item,index)=>
                         {return showMoreBtns(index) && item.action === 'CustomButton'
                          && (item.event === 'URL' || item.event === 'lightning-url')})"
                  :key="idx"
                  class="textItem"
                  @click="handleClick(item, item.action, true)"
                >
                  {{ item.label }}
                  <button
                    v-show="false"
                    :id="item.name"
                    :onclick="item.functionCode"
                  ></button>
                </li>
              </ul>
            </el-popover>
          </li>
        </ul>
      </span>
      <el-button
        style="margin-right: 10px; padding: 8px 7px"
        size="mini"
        @click="mailchimp"
        v-if="isMailChimp"
      >
        <!-- 导出至MailChimp -->
        {{ $t("label.integrations.mailchimp.export") }}
      </el-button>
      <!-- 显示知识库操作错误按钮 -->
      <el-button
        v-if="objectApi == 'CloudccKArticle' && errorisshow"
        type="danger"
        size="mini"
        @click="clickerror"
        style="margin-right: 10px; padding: 8px 7px"
      >
        {{ $t("label.knowledgebase.display.error", { a: errorNum }) }}
      </el-button>
      <el-button
        :loading="toQB"
        v-if="
          (prefix === '032' || prefix === '072' || prefix == '021') &&
          QBtogether
        "
        class="quickbook"
        @click="synctoQB"
        >{{ $t("front-sales-button-toQB") }}</el-button
      >
      <el-button
        :loading="toCC"
        v-if="(prefix === '072' || prefix == '021') && QBtogether"
        class="quickbook"
        @click="syncfromQB"
        style="border-left: none"
        >{{ $t("front-sales-button-fromQB") }}</el-button
      >
      <el-button
        type="primary createBtn pull-right"
        size="mini"
        style="padding: 7px 12px; margin-top: 1px; border: 0; margin-left: 10px"
        v-if="addable"
        @click="add"
        >{{ createBtnName }}</el-button
      >
    </div>
    <!-- 订单列表页生成发票弹窗 -->
    <create-edit-obj
      ref="orderCeateEditObj"
      :object-name="orderNameRecive"
      :prefix="orderPrefix"
      :objectApi="orderApi"
      :isOrderPage="true"
      :orderInfo="orderInfo"
      :checkedLists="selectedList"
    ></create-edit-obj>

    <!-- 批量删除确认 -->
    <el-dialog
      :visible.sync="delDialogVisible"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: #080707">
        <!-- 确认删除选中的{{ selectNum }}条数据吗？ -->
        {{ delContent }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="delDialogVisible = false"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="confirmDel"
          type="primary"
          size="mini"
          :loading="deleteLoadingStatus"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>
    <!--批量新增-->
    <el-dialog
      :visible.sync="batchAddDialog"
      top="10%"
      :title="batchAddDialogTitle"
      width="90%"
      :before-close="closeBatchAddDialog"
    >
      <batchAdd
        ref="batchAdd"
        :prefix="prefix"
        @closeBatchAddDialog="closeBatchAddDialog"
        @refresh="refresh"
        :pageFlag="pageFlag"
        :currentClientPoolSelectObj="currentClientPoolSelectObj"
        @getBatchLoading="getBatchLoading"
      />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="closeBatchAddDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="saveBatchAdd"
          :loading="isBatchLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 转移所有人-->
    <transfer-owner-dialog
      :id="ids"
      :objId="objId"
      :show-select-num="true"
      :select-num="selectNum"
      :isKnowledge="isKnowledge"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      ref="transferClientOwner"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>
    <!-- 批量打分 -->
    <el-dialog
      :title="$t('Lead_Tools_BatchScoring')"
      :visible.sync="scoringVisible"
      width="30%"
      :close-on-click-modal="false"
      class="scoring"
    >
      <div style="margin-bottom: 30px">
        <!-- 点击打分，为当前视图内潜在客户进行打分。 -->
        {{ $t("vue_label_lead_to_score") }}
        <!-- 打分过程可能需要花费较长时间，完成打分后您会收到确认邮件。 -->
        {{ $t("label.lead.score.confirm1") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="scoringBtn" size="mini">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>

    <!-- 批量发送邮件 -->
    <batch-send-email></batch-send-email>
    <!--    资产条形码，二维码弹窗-->
    <code-dialog ref="codeStyleDialog"> </code-dialog>
    <!-- 批量发送短信 -->
    <shortMessageDialogMassHair></shortMessageDialogMassHair>
    <!-- 公海池右边操作按钮对话框【恢复，回收，领取】 -->
    <!-- :title="clientPoolOprationObject.oprationDirctiveName + tabName" -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Recover_Recycle_Get"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <!-- 对话框场景适配   【回收、领取、恢复】-->
      <span v-if="tableCheckedObjectArr.length != 0">
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_recycle')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.recallpotential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm .potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_restore')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.recovery.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
        <span
          style="font-size: 16px; font-weight: bold"
          v-if="
            clientPoolOprationObject.oprationDirctiveName ===
            this.$i18n.t('vue_label_pool_adopt')
          "
        >
          {{
            tableCheckedObjectArr.length > 1
              ? this.$i18n.t("lable.Confirm.potential.customers", {
                  num: tableCheckedObjectArr.length,
                })
              : this.$i18n.t("lable.Confirm.collect.potential.customers", {
                  name: tableCheckedObjectArr[0].name,
                })
          }}
        </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="RecycleGetRecoverClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!--               退回                  -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Back_Discard"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="Back_DiscardClientPoolConfirmEvent()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【退回，废弃】 -->
    <el-dialog
      :visible.sync="seaShow"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div v-loading="Back_Discard_FormOptionListLoading">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <el-form :model="Back_Discard_Form" ref="ruleForm">
            <span style="color: red">*</span>
            <!-- 选择退回的公海池 -->
            {{ $t("lable.Select.the.returned.high.seas.pool") }}
            <br />
            <el-select
              v-model="Back_Discard_Form.backtopoolId"
              prop="backtopoolId"
              :rules="{
                required: true,
                message: '请至少选择一条',
                trigger: ['change', 'blur'],
              }"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in seaoption"
                :key="index"
              />
            </el-select>
          </el-form>
        </div>

        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <span style="color: red">*</span>
          <!-- 原因 -->
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="marketseaReturnAndRecovery()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【转移】 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Transfer"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择转入的公海池 -->
          {{ $t("vue_label_pool_selecttransferinto") }}
        </div>
        <el-select
          v-model="Transfer_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in Transfer_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="TransferClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池右边操作按钮对话框【分配】 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Assign"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div class="clientPoolDialogIsShow_Transfer-box-style">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- 选择分配成员 -->
          {{ $t("vue_label_pool_assignselect") }}
        </div>
        <el-select
          v-model="Assign_Form.firstSelect"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.username"
            :value="item.userid"
            v-for="(item, index) in Assign_FormOptionList"
            :key="index"
          />
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="AssignClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 公海池批量删除 -->
    <el-dialog
      :visible.sync="clientPoolDialogIsShow_Delete"
      :title="$t('label_chatter_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">
        <!-- 确认删除选中的{{ tableCheckedObjectArr.length }}条数据吗？ -->
        {{
          $t("vue_label_pool_deleteconfirm", {
            tableCheckedObjectArrlength: tableCheckedObjectArr.length,
          })
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="DeleteClientPoolConfirmEvent"
          type="primary"
          size="mini"
        >
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>
    <Dialog
      :dialogSign="dialogSign"
      :btnName="btnName"
      :titleType="titleType"
      @dialogEdit="dialogEdit"
      @dialoghandleClose="dialoghandleClose"
    >
      <template v-slot:Dialog>
        <div>
          <div v-if="btnSign == 'draftdel'">
            <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
            <div class="dialog-type">
              {{ $t("label.knowledgebase.unable.restore.draft") }}
            </div>
            <div>
              {{
                $t(
                  "label.knowledgebase.delete.translation.associated.with.draft"
                )
              }}
            </div>
          </div>
          <div v-else-if="btnSign == 'articledel'" class="dialog-type">
            <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
            <div>
              {{ $t("label.knowledgebase.permanently.remove.articles") }}
            </div>
          </div>
          <div v-else-if="btnSign == 'file'" class="dialog-type">
            <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
            <div>{{ $t("label.knowledgebase.published.translations") }}</div>
          </div>
          <div v-else-if="btnSign == 'recovery'" class="dialog-type">
            <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
            <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
          </div>
          <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
            <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
            <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 知识库发布 -->
    <Release
      :ids="ids"
      :transfer-owner-dialog="releasedialogVisible"
      @releasehandleClose="releasehandleClose"
      :markNewVersion="markNewVersion"
      :affectTranslation="affectTranslation"
      @refreshright="refreshright"
    ></Release>
    <!-- 指派 -->
    <Distribution
      :ids="ids"
      :transfer-owner-dialog.sync="assigndialogVisible"
      @assignhandleClose="assignhandleClose"
      @refreshright="refreshright"
    >
    </Distribution>
    <!-- 提交进行翻译 -->
    <Submittranslation
      :ids="ids"
      :tableData="tableData"
      :transfer-owner-dialog="submittranslationdialogVisible"
      @submittranslationhandleClose="submittranslationhandleClose"
      @refreshright="refreshright"
    >
    </Submittranslation>
    <Quickoperationerror
      ref="mychild"
      v-show="warningstate"
      :errortable="errortable"
      @close="close"
      @narrow="narrow"
      @errorchange="Errorchange"
      @refreshright="refreshright"
    ></Quickoperationerror>
    <!-- 项目模板新建 -->
    <el-dialog
      :title="$t('front-project-module-v1-template-newcreate')"
      :visible.sync="createplant"
      width="30%"
      :before-close="createplanthandleClose"
    >
      <el-form
        ref="createplattform"
        :model="createpform"
        :rules="createmplatrules"
        label-width="auto"
        size="small"
      >
        <el-form-item
          :label="$t('front-project-module-v1-select-projecttemplate')"
          prop="region"
        >
          <el-select
            v-model="createpform.region"
            :placeholder="$t('front-project-module-v1-select-projecttemplate')"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in createpformoption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.projectManagement.entryname')"
          prop="name"
        >
          <el-input
            v-model="createpform.name"
            :placeholder="$t('label.projectManagement.Pleaseenterprojectname')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.chatter.startDate')" prop="date">
          <el-date-picker
            type="date"
            :placeholder="$t('label.chatter.startDate')"
            v-model="createpform.date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createplant = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <el-button type="primary" @click="saveteplant">{{
          $t("component.button.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as createInvoice from "./api.js";
import {
  GetMailchimpGetMailChimpPermission,
  BatchLeadScore,
  getEmailPageInfo,
  batchDelete,
  openCall,
  getSelectValue,
  queryProjectTemplateList,
  templateSave,
} from "./api.js";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import * as request from "./api.js";
import {
  updateStatusFromQB,
  sendInvoiceFromQB,
  cloudccOrderToQBEstimate,
  cloudccPaymentToQBPayment,
  syncInvoicesFromQB,
  syncPaymentFromQB,
  cloudccInvoicesToQBInvoices,
  quickbookStart,
} from "./api.js";
import * as knowledgearticlesApi from "./api.js";
import { Loading } from "element-ui";
import DialogMixin from "@/mixin/Dialog.js";

export default {
  mixins: [TransferOwnerMixin, DialogMixin],
  components: {
    TransferOwnerDialog: () =>
      import("@/components/TransferOwnerDialog/index.vue"),
    batchAdd: () => import("@/components/ButtonWrapper/batchAdd"),
    batchSendEmail: () => import("@/views/Email/batchSendEmail"),
    shortMessageDialogMassHair: () =>
      import("@/views/noteSms/components/shortMessageDialogMassHair"),
    CodeDialog: () => import("@/views/commonObjects/components/CodeDialog"),
    Distribution: () => import("@/components/Distribution/index.vue"),
    Submittranslation: () => import("@/components/Submittranslation/index.vue"),
    Dialog: () => import("@/components/Dialog/index.vue"),
    Release: () => import("@/components/Release/index.vue"),
    Quickoperationerror: () => import("@/components/Quickoperationerror/index"),
    createEditObj: () =>
      import("@/views/commonObjects/components/create-edit-obj.vue"),
  },
  props: {
    selectedList: {
      type: Array,
      default: () => [],
    },
    showButtons: {
      type: Array,
    },
    viewId: {
      type: String,
      default: "",
    },
    // 当前对象名称
    tabName: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    addable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    /**
     * 公海池模块参数
     */
    // 表格所选中的所有的对象
    tableCheckedObjectArr: {
      type: Array,
      default: () => [],
    },
    // 页面标记
    pageFlag: {
      type: String,
      default: "",
    },
    // 公海池ID
    clientPoolId: {
      type: String,
      default: "",
    },
    // 当前的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      orderInfo: {},
      orderNameRecive: "",
      orderApi: "Invoices",
      orderPrefix: "021",
      seaoption: [],
      importshow: false,
      createBtnName: this.$i18n.t("label.new"), // 新建按钮名称
      //群发邮件
      batchEmail: "",
      ownerName: "",
      ownerId: "",
      fieldId: "ffe100000001265aWyi8", // 所有人 信息写死
      relevantObjId: "user",
      relevantPrefix: "005",
      showSearchTable: false,
      input: "",
      delDialogVisible: false,
      getchangeowners: [],
      showMoreBtn: false,
      batchAddDialog: false,
      isBatchLoading: false,
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabName,
      }),
      isShowThree: true,
      isSendMail: false,
      transferClientOwnerDialog: false,
      releasedialogVisible: false, //发布
      assigndialogVisible: false,
      submittranslationdialogVisible: false,
      tableData: [],
      btnList: [],
      markNewVersion: "true",
      affectTranslation: "true",
      isKnowledge: "",
      buttonList: [
        {
          // 添加标签
          label: this.$i18n.t("front-generate-v1-invoice"),
          action: "New Invoice",
          name: "New Invoice",
        },
        {
          // 添加标签
          label: this.$i18n.t("label.tag.addtags"),
          action: "addTag",
          name: "addTag",
        },
        {
          // 批量新增
          label: this.$i18n.t("label.batchadd"),
          action: "batchAdding",
          name: "Batch Added",
        },
        {
          // 批量转移
          label: this.$i18n.t("label.batchtransfer"),
          action: "batchRemove",
          name: "Batch Transfer",
        },
        {
          // 批量打分
          label: this.$i18n.t("Lead_Homepage_Tools_BatchScoring"),
          action: "batchScoring",
          name: "batchScoring",
        },
        {
          // 批量删除
          label: this.$i18n.t("label.batchDelete"),
          action: "batchDelete",
          name: "Batch Delete",
        },
        {
          //群发邮件
          label: this.$i18n.t("label.emailobject.groupemail"),
          action: "GroupEmail",
          name: "Group Email",
        },
        {
          // 批量发送短信
          label: this.$i18n.t("label.sendsms.batch"),
          action: "groupSMS",
          name: "Group SMS",
        },
        {
          // Batch Print Asset Code 批量打印资产标签
          label: "批量打印标签",
          action: "batchPrintAsset",
          name: "Batch Print Asset Code",
        },
        {
          //查重合并
          label: this.$i18n.t("label.mergeRechecking"),
          action: "checkDuplicationAndMerge",
          name: "mergeRechecking",
        },
        {
          // 撞单分析
          label: "撞单分析",
          action: "collisionAnalysis",
          name: "myzdfx",
        },
        {
          // 合并
          label: this.$i18n.t("label.checkfield.merge"),
          action: "merge",
          name: "Merge",
        },
        {
          // 领取
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Get",
          name: "get",
        },
        {
          // 分配
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Assign",
          name: "assign",
        },
        {
          // 退回
          label: this.$i18n.t("vue_label_pool_return"),
          action: "returnPool",
          name: "Return Pool",
        },
        {
          // 回退
          // label: this.$i18n.t('vue_label_pool_return'),
          action: "Back",
          name: "back",
        },
        {
          // 回收
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recycle",
          name: "recycle",
        },
        {
          // 废弃
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Discard",
          name: "discard",
        },
        {
          // 恢复
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recover",
          name: "recover",
        },
        {
          // 转移
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Transfer",
          name: "transfer",
        },
        {
          // Send Invoice From QB
          label: "Send Invoice From QB",
          action: "SendQB",
          name: "Send Invoice From QB",
        },
        {
          // Update Status From QB
          label: "Update Status From QB",
          action: "UpdateQB",
          name: "Update Status From QB",
        },
        // 归档
        {
          label: this.$i18n.t("label.group.archived"),
          action: "file",
          name: "Archive",
        },
        // 发布
        {
          label: this.$i18n.t("pagecreator.button.publish"),
          action: "release",
          name: "Publish",
        },
        // 删除文章
        {
          label: this.$i18n.t("label.knowledgebase.delete.article"),
          action: "articledel",
          name: "Delete Article",
        },
        // 分配
        {
          label: this.$i18n.t("vue_label_pool_assign"),
          action: "assign",
          name: "Allot",
        },
        {
          // 删除草稿
          label: this.$i18n.t("label.knowledgebase.delete.draft.t"),
          action: "draftdel",
          name: "Delete Draft",
        },
        {
          // 还原
          label: this.$i18n.t("label.knowledgebase.reduction"),
          action: "recovery",
          name: "Restore",
        },
        {
          // 提交进行翻译
          label: this.$i18n.t("label.knowledgebase.submit.for.translation"),
          action: "submittranslation",
          name: "Submit For Translation",
        },
        {
          // 更改所有人
          label: this.$i18n.t("label.tabpage.cownerz"),
          action: "transferOwner",
          name: "Change Owner",
        },
        {
          // 从模板新建
          label: this.$i18n.t("front-project-module-v1-from-template-create"),
          action: "NewfromTemplate",
          name: "New from Template",
        },
      ],
      val: "",
      num: 5,
      selectNum: 0,
      count: 5,
      restaurants: [],
      recordTypeList: [],
      recordTypeListId: "",
      objId: this.$route.params.objId,
      objectApi: this.$route.params.objectApi,
      state: "",
      stateone: "",
      datalist: [], //批量增长
      fieldlist: [],
      index: "",
      ids: "",
      // prefix: this.$route.params.prefix, // 对象前缀
      checkedList: [], //wj
      scoringVisible: false, // 批量打分
      str: "", //删除看板数据的所有id
      /**
       * 公海池部分参数
       */
      clientPoolDialogIsShow_Recover_Recycle_Get: false, //公海池右边操作按钮对话框【恢复，回收，领取】
      clientPoolDialogIsShow_Back_Discard: false, //公海池右边操作按钮对话框【退回，废弃】
      clientPoolDialogIsShow_Transfer: false, //公海池右边操作按钮对话框【转移】
      clientPoolDialogIsShow_Assign: false, //公海池右边操作按钮对话框【分配】
      clientPoolDialogIsShow_Delete: false, //公海池右边操作按钮对话框【批量删除】
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
      },
      // 退回、废弃表单(选中的参数)
      Back_Discard_Form: {
        firstSelect: "",
        secondDesc: "",
        backtopoolId: "", //退回公海池id
      },
      Back_Discard_FormOptionList: [], //退回、废弃的下拉选项列表
      Back_Discard_FormOptionListLoading: false, //退回、废弃的下拉选项列表请求接口时loading
      Transfer_FormOptionList: [], //转移公海池时查询可转入的公海池列表
      Transfer_Form: { firstSelect: "" }, //转移的表单
      Assign_FormOptionList: [], //分配的选项列表
      Assign_Form: { firstSelect: "" }, //分配的表单
      isMailChimp: null, //MailChimp按钮展示权限
      delContent: "", //删除提示语
      seaShow: false, //退回公海池，
      deleteLoadingStatus: false, //删除、批量删除按钮 loading 状态
      warningstate: false, //错误信息弹窗显示
      loadingOption: {
        // loading配置对象
        target: "#table-panel",
      },
      errorisshow: false,
      errortable: [], //错误信息列表
      errorNum: null, //错误信息个数显示
      createplant: false, //创建项目模板
      createpform: {
        //创建项目模板弹框数据
        date: "",
        name: "",
        region: "",
      },
      //从模板新建 弹框的验证规则
      createmplatrules: {
        date: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-start-time"
            ),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t(
              "front-project-module-v1-plase-input-project-name"
            ),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("front-project-module-v1-plase-selectemplat"),
            trigger: "blur",
          },
        ],
      },
      //从模板新建 弹框的模板列表
      createpformoption: [],
      QBtogether: "", //QB权限
      toQB: false, //同步到QB的loading
      toCC: false, //更新到CC的loading
    };
  },
  mounted() {
    // this.selMarketsea()        www
    if (this.objectApi == "CloudccKArticle") {
      this.warningerror();
    }
  },
  methods: {
    // 生成发票
    async createInvoice() {
      // 获取发票对象信息
      createInvoice.getObjectGetObjectInfo({ id: "invoices" }).then((res) => {
        this.orderNameRecive = res.data.labelname;
      });
      let checkedList = JSON.parse(JSON.stringify(this.selectedList));
      if (checkedList.length === 0) {
        this.$message(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      } else {
        let orderid = [];
        checkedList.forEach((list) => {
          orderid.push(list.id);
        });
        orderid = orderid.join(",");
        let count = 0;
        // 判断全部订单是否为同一个客户
        let result = await createInvoice.isSameCustomer({ orderid });
        if (result.data.flag) {
          this.orderInfo.contract = {
            id: result.data.accountid,
            value: result.data.accountname,
          };
          count++;
        } else {
          this.$message.error(this.$i18n.t("label.invoice.customer.is.error"));
          return;
        }
        // 判断全部订单是否为激活状态
        let result2 = await createInvoice.isActiveOrder({ orderid });
        if (result2.result) {
          count++;
        }
        // 判断所选订单开具发票状态
        let res = await createInvoice.isAlreadyGenerated({ orderid });
        if (res.result) {
          count++;
        }
        // 判断所选订单是否属于同一个币种以及价格手册
        let result3 = await createInvoice.isSameCurencyAndPriceBook({
          orderid,
        });
        if (result3.result) {
          this.orderInfo.pricebook2id = {
            id: result3.data.pricebookId,
            value: result3.data.pricebookname,
          };
          this.orderInfo.currency = result3.data.currency;
          count++;
        }
        if (count === 4) {
          this.$refs.orderCeateEditObj.add();
        }
      }
    },
    //获取QB权限
    gutQBtogether() {
      quickbookStart().then((res) => {
        if (res.result) {
          if (res.data && res.data == "1") {
            this.QBtogether = true;
          } else {
            this.QBtogether = false;
          }
        }
      });
    },
    //同步cloudcc订单信息至QuickBooks并关联
    synctoQB() {
      if (this.selectedList.length > 0 && this.selectedList.length < 11) {
        this.toQB = true;
        let idlist = [];
        this.selectedList.forEach((res) => {
          idlist.push(res.id);
        });
        var params = idlist.join(",");
        if (this.prefix == "032") {
          cloudccOrderToQBEstimate({ id: params }).then((res) => {
            this.toQB = false;
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
            } else {
              if (res.data.length > 0) {
                let lastobj = res.data[res.data.length - 1];
                let lastarr = lastobj.split(",");
                let succmess = Number(
                  lastarr[0].substring(2, lastarr[0].length - 1)
                );
                let errmess = Number(
                  lastarr[1].substring(3, lastarr[1].length - 1)
                );
                if (succmess > 0 && errmess > 0) {
                  this.$message.warning(lastobj);
                } else if (succmess > 0 && errmess == 0) {
                  this.$message.success("成功" + succmess + "条");
                } else if (succmess == 0 && errmess > 0) {
                  this.$message.error("失败" + errmess + "条");
                } else {
                  this.$message.error(
                    this.$i18n.t("lable.Synchronization.failure")
                  );
                }
              }
            }
          });
        } else if (this.prefix == "021") {
          cloudccInvoicesToQBInvoices({ id: params }).then((res) => {
            this.toQB = false;
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
            } else {
              if (res.data.length > 0) {
                let lastobj = res.data[res.data.length - 1];
                let lastarr = lastobj.split(",");
                let succmess = Number(
                  lastarr[0].substring(2, lastarr[0].length - 1)
                );
                let errmess = Number(
                  lastarr[1].substring(3, lastarr[1].length - 1)
                );
                if (succmess > 0 && errmess > 0) {
                  this.$message.warning(lastobj);
                } else if (succmess > 0 && errmess == 0) {
                  this.$message.success("成功" + succmess + "条");
                } else if (succmess == 0 && errmess > 0) {
                  this.$message.error("失败" + errmess + "条");
                } else {
                  this.$message.error(
                    this.$i18n.t("lable.Synchronization.failure")
                  );
                }
              }
            }
          });
        } else if (this.prefix == "072") {
          cloudccPaymentToQBPayment({ id: params }).then((res) => {
            this.toQB = false;
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.companyprofile.syncsuccess")
              );
            } else {
              if (res.data.length > 0) {
                let lastobj = res.data[res.data.length - 1];
                let lastarr = lastobj.split(",");
                let succmess = Number(
                  lastarr[0].substring(2, lastarr[0].length - 1)
                );
                let errmess = Number(
                  lastarr[1].substring(3, lastarr[1].length - 1)
                );
                if (succmess > 0 && errmess > 0) {
                  this.$message.warning(lastobj);
                } else if (succmess > 0 && errmess == 0) {
                  this.$message.success("成功" + succmess + "条");
                } else if (succmess == 0 && errmess > 0) {
                  this.$message.error("失败" + errmess + "条");
                } else {
                  this.$message.error(
                    this.$i18n.t("lable.Synchronization.failure")
                  );
                }
              }
            }
          });
        }
      } else if (this.selectedList.length == 0) {
        this.$message.error(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      } else if (this.selectedList.length > 10) {
        this.$message.error(
          this.$i18n.t("vue_label_commonobjects_view_support_to_datas")
        );
      }
    },
    syncfromQB() {
      if (this.selectedList.length < 11) {
        this.toCC = true;
        let idlist = [];
        this.selectedList.forEach((res) => {
          idlist.push(res.id);
        });
        let params = idlist.join(",");
        if (this.prefix == "021") {
          syncInvoicesFromQB({ id: params }).then((res) => {
            this.toCC = false;
            this.refresh();
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.projectManagement.update.succeeded")
              );
            } else {
              if (res.data.length > 0) {
                if (this.selectedList.length > 0) {
                  let lastobj = res.data[res.data.length - 1];
                  let lastarr = lastobj.split(",");
                  let succmess = Number(
                    lastarr[0].substring(2, lastarr[0].length - 1)
                  );
                  let errmess = Number(
                    lastarr[1].substring(3, lastarr[1].length - 1)
                  );
                  if (succmess > 0 && errmess > 0) {
                    this.$message.warning(lastobj);
                  } else if (succmess > 0 && errmess == 0) {
                    this.$message.success("成功" + succmess + "条");
                  } else if (succmess == 0 && errmess > 0) {
                    this.$message.error("失败" + errmess + "条");
                  } else {
                    this.$message.error(
                      this.$i18n.t("front-verkoop-bywerking-error")
                    );
                  }
                } else {
                  let mess = res.data.substring(0, 9);
                  this.$message.success(mess);
                }
              }
            }
          });
        } else if (this.prefix == "072") {
          syncPaymentFromQB({ id: params }).then((res) => {
            this.toCC = false;
            this.refresh();
            if (res.result && res.data == "") {
              this.$message.success(
                this.$i18n.t("label.projectManagement.update.succeeded")
              );
            } else {
              if (res.data.length > 0) {
                if (this.selectedList.length > 0) {
                  let lastobj = res.data[res.data.length - 1];
                  let lastarr = lastobj.split(",");
                  let succmess = Number(
                    lastarr[0].substring(2, lastarr[0].length - 1)
                  );
                  let errmess = Number(
                    lastarr[1].substring(3, lastarr[1].length - 1)
                  );
                  if (succmess > 0 && errmess > 0) {
                    this.$message.warning(lastobj);
                  } else if (succmess > 0 && errmess == 0) {
                    this.$message.success("成功" + succmess + "条");
                  } else if (succmess == 0 && errmess > 0) {
                    this.$message.error("失败" + errmess + "条");
                  } else {
                    this.$message.error(
                      this.$i18n.t("front-verkoop-bywerking-error")
                    );
                  }
                } else {
                  let mess = res.data.substring(0, 8);
                  this.$message.success(mess);
                }
              }
            }
          });
        }
      } else {
        this.$message.error(
          this.$i18n.t("vue_label_commonobjects_view_support_to_datas")
        );
      }
    },
    //获取对象下公海池
    async selMarketsea(val) {
      let params = {
        id: val,
      };
      let res = await request.queryMarketSeaList(params);
      this.seaoption = res.data;
    },
    init() {
      if (
        (this.objectApi === "Lead"||this.objectApi === "cloudcclead") &&
        this.$router.history.current.params.type !== "kanban"
      ) {
        this.GetMailchimpGetMailChimpPermissions();
      } else if (
        this.objectApi === "Contact" &&
        this.$router.history.current.params.type !== "kanban"
      ) {
        this.GetMailchimpGetMailChimpPermissions();
      } else if (
        this.$router.history.current.params.type !== "kanban" &&
        (this.objectApi !== "Contact" || !(this.objectApi == "Lead"||this.objectApi == "cloudcclead"))
      ) {
        this.isMailChimp = false;
      }

      this.btnList = [];
      this.createBtnName = this.$i18n.t("label.new");
      this.transferClientOwnerDialog = false;

      this.showButtons.forEach((btn) => {
        this.buttonList.forEach((item) => {
          if (btn.name === "New") {
            this.createBtnName = btn.label;
          }
          if (btn.name === item.name) {
            this.$set(item, "label", btn.label);

            this.btnList.push(item);
          }
        });
        if (this.prefix == "032" || this.prefix == "072") {
          this.gutQBtogether();
        } else if (this.prefix == "021") {
          this.btnList.forEach((res, index) => {
            if (
              res.label == "Update Status From QB" ||
              res.label == "Send Invoice From QB"
            ) {
              this.btnList.splice(index, 1);
            }
          });
          this.gutQBtogether();
        } else if (this.prefix == "p08") {
          this.btnList = [];
        }
        // 自定义按钮
        if (btn.category === "CustomButton") {
          this.btnList.push({
            id: btn.id,
            name: btn.name,
            label: btn.label,
            functionCode: btn.functionCode,
            action: "CustomButton",
            event: btn.event,
            behavior: btn.behavior,
          });
        }
      });
    },
    // 显示按钮
    showBtn(idx) {
      return Number(idx) < 4;
    },
    // 在更多中显示按钮
    showMoreBtns(idx) {
      return Number(idx) > 3;
    },
    // 新建
    add() {
      this.$emit("add");
    },
    open(ssr) {
      this.$message(ssr);
    },
    // 批量删除
    batchdelete() {
      if (this.selectNum == 0) {
        this.$confirm(
          this.$i18n.t("lable_opppdt_selPdt_leastone"),
          this.$i18n.t("label.prompt"),
          {
            type: "warning",
          }
        );
      } else {
        if (
          this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone" ||
          this.objectApi === "CloudccProblem" ||
          this.objectApi === "cloudccTask" ||
          this.objectApi === "cloudccSubtask"
        ) {
          this.delContent = this.$i18n.t(
            "front-project-v1-batch-deletion-confirmation-information"
          );
        } else {
          this.delContent = this.$i18n.t(
            "vue_label_commonobjects_view_notice_deletecertification",
            { selectNum: this.selectNum }
          );
        }
        this.delDialogVisible = true;
      }
    },
    // 批量删除确认
    confirmDel() {
      var param = {
        ids: this.ids,
        prefix: this.prefix,
      };
      this.deleteLoadingStatus = true;
      batchDelete(param)
        .then((res) => {
          this.deleteLoadingStatus = false;
          if (
            res.data.deleteerrorlist === undefined ||
            res.data.deleteerrorlist === null
          ) {
            this.$message.success(res.returnInfo);
            this.$parent.refreshAfterBatchDelete();
          } else {
            // let returnInfo = "";
            // res.data.deleteerrorlist.forEach((deleteerror) => {
            //   returnInfo += this.$i18n.t(
            //     "vue_label_commonobjects_view_notice_deletefailreason",
            //     [deleteerror.id, deleteerror.message]
            //   );
            // });
            // this.$message.error(returnInfo);
            let error="数据删除失败，您没有被授权此服务，请联系系统管理员!"
            this.$message.error(error);
            if (
              res.data.deleteerrorlist.length !== param.ids.split(",").length
            ) {
              this.$parent.refreshAfterBatchDelete();
            }
          }
          this.val = "";
          this.delDialogVisible = false;
        })
        .catch(() => {
          this.deleteLoadingStatus = false;
        });
    },
    // 点击空白部分关闭弹窗（公海池开启）
    handleClose(done) {
      done();
      this.delDialogVisible = false;
    },
    // 公海池弹窗主动关闭
    clientPoolCancle() {
      // 弹窗隐藏
      this.clientPoolDialogIsShow_Recover_Recycle_Get = false;
      this.clientPoolDialogIsShow_Back_Discard = false;
      this.clientPoolDialogIsShow_Transfer = false;
      this.clientPoolDialogIsShow_Assign = false;
      this.clientPoolDialogIsShow_Delete = false;
      this.seaShow = false;
    },
    // 公海池的对话框动画执行完毕，自动清除事件指令【交互优化】
    clientPoolClosed() {
      // 清除公海池的弹窗保留的事件名称
      this.clientPoolOprationObject = {};
    },
    // 刷新页面
    refresh() {
      this.$emit("refresh");
    },
    // 处理按钮点击事件
    handleClick(item, action, flag) {
      this.checkedList = JSON.parse(JSON.stringify(this.selectedList));
      var keys = [];
      for (var index in this.recordTypeList) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.recordTypeList.hasOwnProperty(index)) keys.push(index);
      }
      for (var i = 0; i < keys.length; i++) {
        this.index = i + 1;
      }
      if (action == "New Invoice") {
        this.createInvoice();
      }
      // 添加标签
      // if (action == "addTag") {}
      else if (action == "batchAdding") {
        // 批量新增
        this.batchAddDialog = true;
        this.$nextTick(() => {
          this.$refs.batchAdd && this.$refs.batchAdd.init();
          this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
        });
        this.batchAddDialogTitle = `${this.$i18n.t("label.object.batchadd", {
          objName: this.tabName,
        })}`;
      } else if (action == "batchRemove") {
        // 批量转移
        this.selectNum = 0;
        this.ownerName = "";
        this.ownerId = "";
        this.checkList = [];
        if (this.checkedList) {
          this.selectNum = this.checkedList.length;
          if (this.selectNum == 0) {
            var srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 0 && this.selectNum <= 200) {
            var checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.selectNum > 200) {
            var srr2 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr2);
          }
        } else {
          var str = this.$parent.str;
          this.str = str.split(",");
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum == 0) {
            var srr3 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr3);
          } else if (this.selectNum > 0 && this.selectNum <= 200) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.selectNum > 200) {
            var srr4 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr4);
          }
        }
      } else if (action == "batchDelete") {
        // 公海池页面选中数量和提示语检测
        if (this.pageFlag === "clientPool") {
          if (!this.checkCheckedCount()) {
            return;
          }
          this.clientPoolDialogIsShow_Delete = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = "删除";
          return;
        }

        // 批量删除
        if (this.checkedList) {
          this.selectNum = 0;
          for (let i = 0; i < this.checkedList.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum > 0 && this.selectNum <= 200) {
            let checkedList = [];
            for (let i = 0; i < this.checkedList.length; i++) {
              checkedList.push(this.checkedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.batchdelete();
          } else if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        } else {
          let str = this.$parent.str;
          this.str = str.split(",");
          this.selectNum = 0;
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.selectNum > 0 && this.selectNum <= 200) {
            this.ids = str;
            this.batchdelete();
          } else if (this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        }
      } else if (action === "merge") {
        // 合并
        this.selectNum = 0;
        for (let i = 0; i < this.checkedList.length; i++) {
          this.selectNum++;
        }
        if (this.selectNum < 2) {
          // 请选择至少两条数据进行合并
          let srr2 = this.$i18n.t("label.mr.selectTwoData");
          this.open(srr2);
        }
        if (this.selectNum >= 2 && this.selectNum <= 10) {
          let checkedList = [];
          for (let i = 0; i < this.checkedList.length; i++) {
            checkedList.push(this.checkedList[i].id);
          }
          let str = checkedList.join(",");
          this.$router.push({
            path: "/commonObjects/combination",
            query: {
              ids: str,
              prefix: this.prefix,
              labelName: this.tabName,
            },
          });
        }
        if (this.selectNum > 10) {
          let srr1 = this.$i18n.t(
            "vue_label_commonobjects_view_support_to_datas"
          );
          this.open(srr1);
        }
      } else if (
        action === "checkDuplicationAndMerge" ||
        action === "collisionAnalysis"
      ) {
        // 查重合并
        this.$router.push({
          path: "/commonObjects/cnki",
          query: {
            prefix: this.prefix,
            viewId: this.viewId,
            objId: this.$route.params.objId,
            objApi: this.objectApi,
          },
        });
      } else if (action == "NewfromTemplate") {
        this.createplantevevt();
      } else if (action === "batchScoring") {
        if (
          this.viewId !== "0" &&
          this.viewId !== "1" &&
          this.viewId !== "2" &&
          this.viewId !== "3"
        ) {
          this.scoringVisible = true;
        } else {
          this.scoringVisible = false;
        }
      } else if (action === "GroupEmail") {
        getEmailPageInfo({}).then((result) => {
          if (result.data && result.data.relatedActiveMailSetting == true) {
            if (this.checkedList.length > 0) {
              let ids = this.checkedList.map((res) => res.id).join();
              let tabName = "";
              if (this.prefix === "004") {
                tabName = "lead";
              } else if (this.prefix == "003") {
                tabName = "contact";
              }
              let option = {
                ids: ids,
                type: tabName,
              };
              this.batchEmail = "true";
              this.$Bus.$emit("deliver-list-array", option);
            } else {
              this.batchEmail = "false";
              this.$Bus.$emit("deliver-zero-array", this.checkedList);
            }
          } else {
            this.$router.push({ path: "/relation" });
          }
        });
      } else if (action === "groupSMS") {
        if (this.checkedList.length > 0) {
          let tabName = "";
          if (this.prefix === "004") {
            tabName = "lead";
          } else if (this.prefix === "003") {
            tabName = "contact";
          }
          this.$Bus.$emit("deliver-sms-data", this.checkedList, tabName);
        } else {
          this.$alert(this.$i18n.t("label.attendance.onrecord"), {
            //请至少选择一条联系人
            confirmButtonText: this.$i18n.t("label.confirm"), //确定
          });
          // this.$Bus.$emit("deliver-select-array", this.checkedList);
        }
      } else if (action === "batchPrintAsset") {
        // 批量打印资产标签
        if (this.checkedList.length > 0) {
          let assetIdArray = [];
          this.checkedList.forEach((item) => {
            assetIdArray.push(item.id);
          });
          this.$refs.codeStyleDialog.openDialog(assetIdArray, "table");
        } else {
          this.$message.warning("请至少勾选一条记录");
        }
      } else if (action === "Get") {
        // 领取公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_adopt"
        ); //领取
      } else if (action === "Assign") {
        // 清除之前表单
        this.clearClientPoolFormDataFun();
        // 分配公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        /**
         * 参数名	必选	类型	说明
          marketseaId	是	string	公海池id
         */
        request
          .getMarketseaSpecMem({
            marketseaId: this.clientPoolId,
          })
          .then((res) => {
            if (!res.result) {
              this.$message.error(res.stackTrace);
              return;
            }
            this.Assign_FormOptionList = res.data;
            this.clientPoolDialogIsShow_Assign = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
              "vue_label_pool_assign"
            ); //分配
          });
      } else if (action === "Back") {
        if (this.$route.params.objId === "account") {
          this.selMarketsea("account");
        } else if (this.$route.params.objId === "lead") {
          this.selMarketsea("lead");
        }
        // 退回公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前【 退回、废弃 】 操作的选项绑定的参数
        this.clearClientPoolFormDataFun();
        //  废弃、退回 下拉操作列表获取
        this.Back_Discard_FormOptionListFun("Back");
        // 弹窗显示
        this.clientPoolDialogIsShow_Back_Discard = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_return"
        ); //退回
      } else if (action === "Recycle") {
        // 回收公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_recycle"
        ); //回收
      } else if (action === "Discard") {
        // 废弃公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前【 退回、废弃 】 操作的选项绑定的参数
        this.clearClientPoolFormDataFun();
        //  废弃、退回 下拉操作列表获取
        this.Back_Discard_FormOptionListFun("Discard");
        // 弹窗显示
        this.clientPoolDialogIsShow_Back_Discard = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_discard"
        ); //废弃
      } else if (action === "Recover") {
        // 恢复公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        this.clientPoolDialogIsShow_Recover_Recycle_Get = true;
        this.clientPoolOprationObject.oprationDirctive = action;
        this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
          "vue_label_pool_restore"
        ); //恢复
      } else if (action === "Transfer") {
        // 转移公海池
        // 公海池勾选的客户数量检测
        if (!this.checkCheckedCount()) {
          return;
        }
        // 清除之前转移的表单
        this.clearClientPoolFormDataFun();
        // 获取可转移的公海池列表选项
        request
          .selMarketsea({
            objid: this.objId,
          })
          .then((res) => {
            res.result && (this.Transfer_FormOptionList = res.data);
            if (!res.result) {
              this.$message.error();
              return;
            }
            this.clientPoolDialogIsShow_Transfer = true;
            this.clientPoolOprationObject.oprationDirctive = action;
            this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
              "vue_label_pool_move"
            ); //转移
          });
      } else if (action == "returnPool") {
        if (this.selectedList.length < 1) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
          });
        } else if (this.selectedList.length > 50) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("label.report.selected.field.cannot"),
          });
        } else {
          this.seaShow = true;
          this.clientPoolOprationObject.oprationDirctive = action;
          this.clientPoolOprationObject.oprationDirctiveName = this.$i18n.t(
            "vue_label_pool_return"
          ); //退回
        }

        if (this.$route.params.objId === "account") {
          this.selMarketsea("account");
        } else if (this.$route.params.objId === "lead") {
          this.selMarketsea("lead");
        }
        this.Back_Discard_FormOptionListFun("Back");
      } else if (item.action === "CustomButton") {
        // lightning版本JavaScript类型自定义按钮
        if (item.event === "lightning") {
          this.selectNum = this.checkedList.length;
          let checkedList = [];
          for (let i = 0; i < this.checkedList.length; i++) {
            checkedList.push(this.checkedList[i].id);
          }
          this.ids = checkedList.join(",");

          // 自定义按钮
          let param = JSON.parse(item.functionCode);
          param.id = this.ids;
          param.token = this.$CCDK.CCToken.getToken()
          if (param.maxRecord && this.selectNum > param.maxRecord) {
            this.$message.warning(
              `最多只能选中${param.maxRecord}条数据进行操作`
            );
          } else {
            if (param.params && param.params.length > 0) {
              // 替换id
              param.params.forEach((item) => {
                if (item.argValue === "{!ids}") {
                  if (this.selectNum < 1) {
                    this.$message.warning(
                      this.$i18n.t("lable.opppdt.selPdt.leastone")
                    );
                  }
                  item.argValue = this.ids;
                }
                if (item.argValue === "{!viewId}") {
                  item.argValue = this.viewId;
                }
                if (item.token === "{!binding}") {
                  item.token = this.$CCDK.CCToken.getToken();
                }
              });
            }

            let loadingInstance = Loading.service(this.loadingOption);
            openCall(param).then((res) => {
              loadingInstance.close();
              if (res.data && res.data.action) {
                // 刷新页面
                if (res.data.action === "refresh") {
                  this.refresh();
                } else if (res.data.action === "redirect") {
                  // 重定向 需判断当前窗口打开还是新窗口打开
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    window.location.href = res.data.url;
                  }
                } else if (res.data.action === "alert") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                }
                //先弹窗，在当前页面刷新
                else if (res.data.action === "alert_refresh") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
                //先弹窗,在新标签页跳转
                else if (res.data.action === "alert_redirect") {
                  // 不同类型的消息提示   wj
                  this.$message({
                    message: res.data.message,
                    type: res.data.messageType || "info",
                  });
                  setTimeout(() => {
                    // 重定向 需判断当前窗口打开还是新窗口打开
                    if (res.data.mode === "new") {
                      window.open(res.data.url);
                    } else if (res.data.mode === "now") {
                      if (`/${window.location.hash}` === res.data.url) {
                        window.location.reload();
                      } else {
                        window.location.href = res.data.url;
                      }
                    }
                  }, 1000);
                }
              }
            });
          }
        }
        // 自定义按钮lightning-script
        if(item.event === 'lightning-script'){
          let funText = item.function_code ? item.function_code : item.functionCode
            let newFun = Function(funText)
            newFun()
          }
        if (item.event === "lightning-url") {
          let searchUrl = item.functionCode.split("?")[1];
          let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
          if (paramsObj.isNeedRecordId && paramsObj.isNeedRecordId == "1") {
            // 需要选记录
            if (this.checkedList.length == 0) {
              this.$message.warning(
                this.$i18n.t("lable.opppdt.selPdt.leastone")
              );
              return;
            }
          }
          let ids = [];
          let idsKey = new Date().getTime();
          for (let i = 0; i < this.checkedList.length; i++) {
            ids.push(this.checkedList[i].id);
          }
          ids = ids.join(",");
          localStorage.setItem(idsKey, ids);
          // 当前窗口打开
          if (item.behavior === "self") {
            if (item.functionCode.substring(0, 7) == "cloudcc") {
              // 新版自定义页面
              let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
              let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
              let page = paramsObj.page;
              // 判断是否显示菜单栏
              let routeData = "";
              if (item.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: {
                    page: page,
                    viewId: this.viewId,
                    idsKey: idsKey,
                    button: "Home",
                  },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: {
                    page: page,
                    viewId: this.viewId,
                    idsKey: idsKey,
                  },
                });
              }
              if (paramsObj.target) {
                if (paramsObj.target == "dialog") {
                  let obj = {
                    title: paramsObj.dialogTitle || "详情",
                    width: paramsObj.dialogWidth || "50%",
                    height: paramsObj.dialogHeight || "",
                    pageApi: page,
                    idsKey: idsKey,
                  };
                  this.$bus.$emit("openDialog", obj);
                } else if (paramsObj.target == "window") {
                  window.open(
                    routeData.href,
                    "newWindow",
                    "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                  );
                }
              } else {
                window.open(routeData.href, "_self");
              }
            } else {
              window.location.href = `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`;
            }
          } else {
            if (item.functionCode.substring(0, 7) == "cloudcc") {
              let searchUrl = item.functionCode.split("?")[1]; // 获取从？开始的url部分
              let paramsObj = this.$qs.parse(searchUrl); // 将参数转为对象
              let page = paramsObj.page;
              // 判断是否显示菜单栏
              let routeData = "";
              if (item.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: {
                    page: page,
                    viewId: this.viewId,
                    idsKey: idsKey,
                    button: "Home",
                  },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: {
                    page: page,
                    viewId: this.viewId,
                    idsKey: idsKey,
                  },
                });
              }
              window.open(routeData.href, "_blank");
            } else {
              window.open(
                `${item.functionCode}?viewId=${this.viewId}&idsKey=${idsKey}`
              );
            }
          }
        }
        if (item.event === "JavaScript" || item.event === "URL") {
          // JavaScript和URL类型提示切换系统
          this.$message.warning(this.$i18n.t("lable.Please.switch"));
        }
      } else if (action == "UpdateQB") {
        //发票页面按钮
        if (this.selectedList.length < 1) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
          });
        } else {
          if (this.selectedList.length >= 200) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("label.Select.maximumecords.operate"),
            });
            return;
          }
          updateStatusFromQB({
            accessToken: this.$CCDK.CCToken.getToken(),
            ids: sessionStorage.getItem("id"),
          }).then((res) => {
            if (res.result == true) {
              this.$message({
                type: "success",
                message: this.$i18n.t("label.companyprofile.syncsuccess"),
              });
            } else {
              this.$message({
                type: "warning",
                message: this.$i18n.t("lable.Synchronization.failure"),
              });
            }
          });
        }
      } else if (action == "SendQB") {
        //发票页面按钮
        if (this.selectedList.length < 1) {
          this.$message({
            type: "warning",
            message: this.$i18n.t("lable.opppdt.selPdt.leastone"),
          });
        } else {
          if (this.selectedList.length >= 200) {
            this.$message({
              type: "warning",
              message: this.$i18n.t("label.Select.maximumecords.operate"),
            });
            return;
          }
          sendInvoiceFromQB({
            accessToken: this.$CCDK.CCToken.getToken(),
            ids: sessionStorage.getItem("id"),
          }).then((res) => {
            if (res.result == true) {
              this.$message({
                type: "success",
                message: this.$i18n.t("label.companyprofile.syncsuccess"),
              });
            } else {
              this.$message({
                type: "warning",
                message: this.$i18n.t("lable.Synchronization.failure"),
              });
            }
          });
        }
      } else if (item.action == "draftdel") {
        //删除草稿
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "draftdel");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "recovery") {
        //还原
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "recovery");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "submittranslation") {
        //提交进行翻译
        //查询语言接口
        let params = {
          id: "",
        };
        knowledgearticlesApi.queryLanguage(params).then((res) => {
          for (let i in res.data.languageList) {
            res.data.languageList[i].data = [
              {
                id: res.data.languageList[i].default_assignee,
                name: res.data.languageList[i].assignee_name,
              },
            ];
            res.data.languageList[i].id = Number(i);
          }
          res.data.languageList.map((o) => {
            return Object.assign(o, { value: o.default_assignee });
          });
          res.data.languageList.map((item) => {
            return Object.assign(item, { ownerType: item.assignee_ownertype });
          });
          this.tableData = res.data.languageList;
        });
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid;
          this.submittranslationdialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "articledel") {
        //删除文章
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "articledel");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "assign") {
        //只知识分配
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.assigndialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "file") {
        //归档
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.dialogAssembly("", "file");
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (item.action == "release") {
        //发布
        if (this.selectedList.length > 0) {
          let thierid = [];
          for (let i in this.selectedList) {
            thierid.push(this.selectedList[i].id);
          }
          this.ids = thierid.join(",");
          this.releasedialogVisible = true;
        } else {
          // 请至少勾选一条记录
          this.$message.warning(
            this.$i18n.t("label.knowledgebase.please.check.record")
          );
        }
      } else if (action == "transferOwner") {
        //知识文章批量更改所有人
        this.isKnowledge = "true";
        this.selectNum = 0;
        this.ownerName = "";
        this.ownerId = "";
        this.checkList = [];
        if (this.selectedList) {
          this.selectNum = this.selectedList.length;
          if (this.prefix === "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (
            this.prefix === "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            let checkedList = [];
            for (let i = 0; i < this.selectedList.length; i++) {
              checkedList.push(this.selectedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix === "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
          if (
            this.prefix !== "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            let checkedList = [];
            for (let i = 0; i < this.selectedList.length; i++) {
              checkedList.push(this.selectedList[i].id);
            }
            this.ids = checkedList.join(",");
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix !== "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.prefix !== "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        } else {
          let str = this.$parent.str;
          this.str = str.split(",");
          for (let i = 0; i < this.str.length; i++) {
            this.selectNum++;
          }
          if (this.prefix === "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (
            this.prefix === "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix === "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
          if (
            this.prefix !== "001" &&
            this.selectNum > 0 &&
            this.selectNum <= 200
          ) {
            this.ids = str;
            this.initTransferOwner(this.objId, this.ids);
            this.transferClientOwnerDialog = true;
          } else if (this.prefix !== "001" && this.selectNum == 0) {
            let srr1 = this.$i18n.t("lable.opppdt.selPdt.leastone");
            this.open(srr1);
          } else if (this.prefix !== "001" && this.selectNum > 200) {
            let srr1 = this.$i18n.t("vue_label_commonobjects_view_up_to_data");
            this.open(srr1);
          }
        }
      }
      if (flag) {
        this.showMoreBtn = !this.showMoreBtn;
      }
      this.$emit(action);
    },
    customButton(item) {
      if (item.event === "JavaScript" || item.event === "URL") {
        // JavaScript和URL类型提示切换系统
        this.$message.warning(this.$i18n.t("lable.Please.switch"));
      }
    },
    //批量打分
    scoringBtn() {
      BatchLeadScore({ viewId: this.viewId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_lead_score_successsful"),
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
      this.scoringVisible = false;
    },
    // 保存批量新增
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    // 关闭批量新增
    closeBatchAddDialog() {
      this.batchAddDialog = false;
    },
    //获取批量新增保存loading状态
    getBatchLoading(n) {
      this.isBatchLoading = n;
    },
    // 更改所有人
    saveTransferOwnerSuccess() {
      this.$emit("refresh");
      this.closeTransferOwnerDialog();
      if (this.objectApi == "CloudccKArticle") {
        this.warningerror();
      }
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    //查找所有人
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelect(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    /**
     * 公海池部分事件
     */
    // 公海池弹窗二次确认事件调用（发射）
    // 回收、领取、恢复
    RecycleGetRecoverClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle(this.clientPoolOprationObject);
    },
    // 退回按钮
    Back_DiscardClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Back_Discard_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 退回公海池，废弃
    async marketseaReturnAndRecovery() {
      let id = this.selectedList.map((item) => item.id).join(",");
      let params = {
        marketseaid: this.Back_Discard_Form.backtopoolId,
        objectApi: this.objectApi,
        thyyms: this.Back_Discard_Form.secondDesc,
        id,
        type: "retuen",
        rejectreason: this.Back_Discard_Form.firstSelect,
      };
      let res = await request.getReturnSeasPool(params);
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
      if (res.result == true) {
        this.Back_Discard_Form.backtopoolId = "";
        this.Back_Discard_Form.firstSelect = "";
        this.Back_Discard_Form.secondDesc = "";
        this.$emit("change");
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 转移
    TransferClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Transfer_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 分配
    AssignClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Assign_Form //表单数据
      );

      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 批量删除
    DeleteClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 公海池勾选的客户数量检测
    checkCheckedCount() {
      if (this.tableCheckedObjectArr.length === 0) {
        this.$message.warning(this.$i18n.t("lable.opppdt.selPdt.leastone")); //请选择至少1条数据
        return false;
      } else if (this.tableCheckedObjectArr.length > 200) {
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        ); //最多选择200条数据
        return false;
      } else {
        return true;
      }
    },
    // 废弃、退回 下拉操作列表获取
    Back_Discard_FormOptionListFun(status) {
      /**
          ffeleadmarketsea05  潜在客户 退回原因
          ffeaccountmarketsea5  客户

          ffeleadmarketsea07 潜在 客户废弃
          ffeaccountmarketsea7 客户废弃
         */
      let fieldId = null;
      if (this.objId === "lead") {
        //  如果是潜在客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeleadmarketsea05";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeleadmarketsea07";
        }
      } else if (this.objId === "account") {
        //  如果是客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeaccountmarketsea5";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeaccountmarketsea7";
        }
      }
      // 加载中
      this.Back_Discard_FormOptionListLoading = true;
      // 获取选项下拉列表
      getSelectValue({
        fieldId,
      }).then((res) => {
        res.result && (this.Back_Discard_FormOptionList = res.data);
        // 加载中
        this.Back_Discard_FormOptionListLoading = false;
      });
    },
    // 公海池每次打开某个对话框，清除之前表单记录
    clearClientPoolFormDataFun() {
      // 退回、废弃表单(选中的参数)
      this.Back_Discard_Form.firstSelect = "";
      this.Back_Discard_Form.secondDesc = "";
      //转移的表单
      this.Transfer_Form.firstSelect = "";
      //分配的表单
      this.Assign_Form.firstSelect = "";
    },
    async GetMailchimpGetMailChimpPermissions() {
      //获取Mailchimp权限
      let res = await GetMailchimpGetMailChimpPermission();
      if (
        res.data &&
        (this.objectApi === "Lead" ||this.objectApi === "cloudcclead"|| this.objectApi === "Contact")
      ) {
        this.isMailChimp = true;
      } else {
        this.isMailChimp = false;
      }
    },
    mailchimp() {
      this.$emit("mailchimp");
    },
    //创建项目模板 关闭弹框
    createplanthandleClose() {
      this.createplant = false;
    },
    //知识库发布关闭弹窗
    releasehandleClose() {
      this.releasedialogVisible = false;
    },
    // 指派
    assignhandleClose() {
      this.assigndialogVisible = false;
    },
    // 提交进行翻译
    submittranslationhandleClose() {
      this.submittranslationdialogVisible = false;
    },
    //知识文章操作刷新
    refreshright() {
      this.$emit("refresh");
    },
    clickerror() {
      this.warningstate = true;
      this.warningerror();
    },
    warningerror() {
      // 获取快速操作错误信息
      let params = {
        articleId: "",
      };
      knowledgearticlesApi.getErrorInfo(params).then((res) => {
        this.errortable = res.data;
        if (this.errortable.length > 0) {
          this.errorisshow = true;
          this.errorNum = this.errortable.length;
        }
      });
      if (this.warningstate) {
        this.$refs.mychild.open();
      }
    },
    Errorchange(num) {
      this.errorNum = num;
      if (this.errorNum > 0) {
        this.errorisshow = true;
      } else {
        this.errorisshow = false;
      }
    },
    narrow() {
      this.warningstate = false;
    },
    close() {
      this.errortable = [];
      this.warningstate = false;
    },
    //项目模板新建
    createplantevevt() {
      queryProjectTemplateList().then((res) => {
        if (res.result) {
          if (res.data && res.data.length > 0) {
            this.createplant = true;
            this.createpformoption = res.data;
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var nowDate =
              year +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              (day < 10 ? "0" + day : day);
            this.createpform.date = nowDate;
            this.createpform.region = this.createpformoption[0].id;
            this.createpform.name = "";
            this.$refs.createplattform.resetFields();
          } else {
            this.$message.warning(
              this.$i18n.t("front-project-module-v1-no-selectemplat")
            );
          }
        }
      });
    },
    //保存项目模板
    saveteplant() {
      this.$refs.createplattform.validate((valid) => {
        this.$message(this.$i18n.t("label.project.template.one"));
        if (valid) {
          let params = {
            projectName: this.createpform.name,
            projectId: this.createpform.region,
            startDate: this.createpform.date,
          };
          templateSave(params).then((res) => {
            if (res.result) {
              this.$router.push({
                path: `/commonObjects/project-detail/${res.data.id}/DETAIL`,
              });
              this.createplant = false;
            } else {
              this.$message.error(this.$i18n.t("label.custombutton.newerror"));
            }
          });
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
          return false;
        }
      });
    },
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      this.objId = nval.params.objId;
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    clientPoolCheckDialogTitle(){
      if (this.objId === "lead") {
        //  如果是潜在客户
        return this.$i18n.t("vue_label_normal_lead_sf");
      } else if (this.objId === "account") {
        //  如果是客户
        return this.$i18n.t("vue_label_normal_account_sf");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }
  .quickbook {
    height: 32px;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #dedcda;
    box-shadow: none;
    font-size: 12px;
    cursor: pointer;
    color: #006dcc;
    margin: 0;
    border-radius: 0px;
  }
  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        width: 29px;
        height: 27px;
        font-size: 18px;
        vertical-align: baseline;
        overflow: inherit;
        border-left: 1px solid #dedcda;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
.masstransfer {
  height: 18px;
  font-size: 18px;
  margin-top: 34px;
  margin-left: 35px;
  font-weight: bold;
  color: #666666;
  display: inline-block;
  line-height: 18px;
}
/*.al::-webkit-scrollbar-track {
  !*display: none;*!
  width: 5px;
}*/
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;

  // &:hover{
  //   color:blue;
  // }
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}
.dialog-type {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
</style>
